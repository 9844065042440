.resident-invite * {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #212934;
}
.resident-invite .pdf-wrapper {
  width: 612pt;
  height: 792pt;
  display: flex;
  flex-direction: column;
  /* lob guidelines */
  /* border: 1pt solid red; */
}
.resident-invite .logo {
  margin-top: 22pt;
  margin-bottom: 12.5pt;
  width: 97.5pt;
  height: 16.5pt;
}
.resident-invite .drawing {
  width: 228pt;
  margin-right: 15pt;
}
.resident-invite .content {
  width: 528pt;
  margin: 0 42pt;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.resident-invite h1 {
  font-weight: 700;
}
.resident-invite h2 {
  font-weight: 700;
}
.resident-invite h3 {
  font-weight: 700;
  font-size: 14pt;
  line-height: 17.6pt;
}
.resident-invite h4 {
  font-weight: 700;
  font-size: 10pt;
  line-height: 12.5pt;
}
.resident-invite h5 {
  font-weight: 700;
  font-size: 9pt;
  line-height: 11.3pt;
}
.resident-invite p {
  font-size: 8pt;
  line-height: 11pt;
}
.resident-invite .bold {
  font-weight: 600;
}
.resident-invite .italic {
  font-style: italic;
}
.resident-invite .primary {
  color: #00767C;
}
.resident-invite .lg-spacing {
  margin-top: 18pt;
}
.resident-invite .md-spacing {
  margin-top: 12pt;
}
.resident-invite .sm-spacing {
  margin-top: 10pt;
}
.resident-invite .xs-spacing {
  margin-top: 4pt;
}
.resident-invite .xxs-spacing {
  margin-top: 2pt;
}
.resident-invite .utility-table {
  border-collapse: collapse;
}
.resident-invite .utility-table thead {
  background-color: #00767C;
}
.resident-invite .utility-table thead td {
  color: #fff;
  padding: 5px;
  font-weight: 700;
  font-size: 7pt;
  line-height: 10pt;
}
.resident-invite .utility-table tbody td {
  padding: 6px;
  border-bottom: 0.5px solid #CFD6DE;
  font-size: 7pt;
  line-height: 10pt;
}
.resident-invite .small-text {
  font-size: 7pt;
  line-height: 10pt;
}
.resident-invite .light-text {
  color: #4B617C;
}
.resident-invite .icon {
  vertical-align: middle;
  margin-right: 3pt;
  width: 12pt;
  height: 12pt;
  display: inline-block;
}
.resident-invite .icon svg {
  width: 10pt;
  height: 10pt;
}
@keyframes dotdotdot {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}
.generating {
  font-size: 30px;
  font-weight: bold;
  display:inline-block;
  clip-path: inset(0 0.8ch 0 0);
  animation: dotdotdot 1s steps(4) infinite;
}
.resident-invite .top-sections {
  display: flex;
  align-items: center;
  margin-bottom: 30pt;
}
.resident-invite .top-right-block {
  width: 100%;
}
.resident-invite .show-paper {
  display: none;
}
.resident-invite .footer {
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding-bottom: 10pt;
  margin-top: 30pt;
}
/* .resident-invite .section-big {
  flex-grow: 1;
} */

/* styles for paper print */

.resident-invite__isPaper * {
  color: #000;
}
.resident-invite__isPaper .section {
  /* lob guidelines */
  /* border: 1pt solid blue; */
  height: 264pt;
  display: flex;
  flex-direction: column;
}
.resident-invite__isPaper .lob-address {
  width: 228pt;
  height: 148pt;
  /* lob guidelines */
  /* background-color: red;
  opacity: 0.1; */
}
.resident-invite__isPaper .top-right-block {
  margin-left: 42pt;
  flex-grow: 1;
}
.resident-invite__isPaper .show-paper {
  display: initial;
}
.resident-invite__isPaper .show-e {
  display: none;
}
.resident-invite__isPaper .primary {
  color: #000;
}
.resident-invite__isPaper .utility-table thead {
  background-color: initial;
  border: 1pt solid #000;
}
.resident-invite__isPaper .utility-table thead td {
  color: #000;
}
.resident-invite__isPaper .light-text {
  color: #000;
}
.resident-invite__isPaper .utility-table tbody td {
  border-bottom: 0.5px solid rgba(0,0,0,0.2);
}
.resident-invite__isPaper .icon svg path {
  fill: #000;
}
.resident-invite__isPaper .top-sections {
  margin-bottom: 0;
}
.resident-invite__isPaper .drawing {
  display: none;
}