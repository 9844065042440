.statement * {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #212934;
  font-size: 7pt;
  line-height: 8.3pt;
}

.statement a {
  text-decoration: none;
  color: #00767C;
  font-size: 7pt;
  line-height: 8.3pt;
}

.statement hr {
  border: 0.1pt dashed #CFD6DE;
  margin: 0 4pt;
}

.statement table {
  border-spacing: 0 !important;
}

.statement table tr {
  position: relative;
}

.statement table td {
  white-space: nowrap;
  padding-top: 5pt;
}

.statement h1 {
  font-weight: 700;
  line-height: 14.04pt;
  font-size: 12pt;
}

.statement h2 {
  font-size: 10pt;
  line-height: 11.85pt;
  font-weight: 700;
}

.statement h3 {
  font-size: 9pt;
  line-height: 10pt;
  margin-top: 2pt;
}

.statement h4 {
  font-size: 8pt;
  line-height: 9.3pt;
  font-weight: 700;
}

.statement h5 {
  font-size: 7pt;
  line-height: 8.11pt;
  font-weight: 700;
}

.statement .icon {
  width: 10pt;
  height: 10pt;
  vertical-align: middle;
  margin-right: 4pt;
}

.statement .table-line {
  width: 100%;
}

.statement .payment-options {
  display: flex;
  align-items: center;
  padding-bottom: 8pt;
}

.statement .payment-table {
  flex-grow: 1;
  margin-top: 5pt;
}

.statement .qr-block {
  width: 110pt;
  display: flex;
  align-items: center;
  border-left: 1pt solid #E1E7EC;
  padding-left: 5pt;
  margin-left: 5pt;
  margin-top: 5pt;
}

.statement .qr-block img {
  margin-right: 5pt;
}

.statement .bold-text {
  font-weight: 700;
  font-size: inherit;
  vertical-align: middle;
  white-space: nowrap;
}

.statement .bold-text s {
  font-weight: 700;
}

.statement .bold-text a {
  font-weight: 700;
  font-size: 6pt;
  font-size: inherit;
  color: black;
}

.statement .text-right {
  text-align: right;
}

.statement .section-title {
  background-color: #4B617C;
  padding: 6pt 10pt;
  color: white;
  font-weight: 700;
  margin-top: 10pt;
}

.statement .section-title--primary {
  background-color: #00767C;
}

.statement .light-text {
  color: #4B617C;
  font-size: inherit;
}

.statement .tenant-id {
  margin-top: 3pt;
}

.statement .half-section-container {
  display: flex;
}

.statement .half-section {
  width: 50%;
}

.statement .half-section+.half-section {
  margin-left: 10pt;
}

.statement .fees-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.statement .pdf-wrapper {
  width: 612pt;
  height: 792pt;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  display: flex;
  flex-direction: column;
  /* lob guidelines */
  /* border: 1pt solid red; */
}

.statement .pdf-content {
  width: 528pt;
  margin: 0 42pt;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.statement .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 22pt;
  margin-bottom: 12.5pt;
  text-align: right;
}

.statement .balance {
  border-top: 3pt solid #00767C;
  display: flex;
  width: 100%;
}

.statement .tenant-info {
  background-color: #F8F9FA;
  padding: 15pt;
  width: 200pt;
  margin-right: 50pt;
}

.statement .tenant-address {
  margin-top: 5pt;
}

.statement .tenant-address p {
  font-size: 7pt;
  line-height: 8.3pt;
}

.statement .tenant-address p+p {
  margin-top: 1pt;
}

.statement .tenant-balance p {
  font-size: 7pt;
  line-height: 8.3pt;
}

.statement .tenant-balance__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statement .mt-sm {
  margin-top: 3pt;
}

.statement .tenant-balance__summary {
  margin-left: 5pt;
  text-align: right;
}

.statement .tenant-balance__history {
  margin-top: 8pt;
}

.statement .right {
  text-align: right;
}

.statement .total {
  font-size: 12pt;
  line-height: 14.04pt;
  color: #00767C;
  font-weight: 600;
}

.statement .primary-color {
  color: #00767C;
}

.statement .saver {
  background-color: #E3F0FC;
  padding: 10pt;
  margin-bottom: 10pt;
  width: 100%;
}

.statement .saver h5 {
  margin-bottom: 5pt;
}

.statement .saver p+p {
  margin-top: 5pt;
}

.statement .saver p {
  line-height: 8pt;
}

.statement .footer-links {
  margin-top: 15pt;
  text-align: center;
}

.statement .footer-link {
  font-size: 5pt;
  line-height: 5.93pt;
}

.statement .footer-link+.footer-link {
  border-left: 1pt solid #E1E7EC;
  padding-left: 5pt;
  margin-left: 5pt;
}

.statement .main-pdf-content {
  flex-grow: 1;
}

.statement .small-text {
  font-size: 7pt;
  line-height: 8.11pt;
  vertical-align: middle;
}

.statement .charge-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: break-spaces;
}

.statement .MuiSvgIcon-root {
  width: 10pt;
  height: 10pt;
  vertical-align: middle;
  margin-right: 4pt;
}

.statement .nowrap {
  white-space: nowrap;
}

.statement .charge-wrapper .small-text {
  padding-left: 2pt;
}

.statement p.autopay-note {
  font-size: 6.5pt;
  line-height: 7pt;
}

.statement p.autopay-note>b {
  font-weight: 800;
  font-size: 6.5pt;
  line-height: 7pt;
}

.statement .row-with-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2pt 0;
}

.statement .rows-with-line {
  margin-top: 5pt;
  width: 100%;
}

.statement .month-charges {
  padding-left: 10pt;
}

.statement .month-credits {
  padding-left: 10pt;
}

.statement .line {
  border-bottom: 1pt dashed #CFD6DE;
  width: 100%;
  margin: 0 5pt;
}

.statement .top-sections {
  display: flex;
}

.statement .logo {
  width: 97.5pt;
  height: 16.5pt;
}

.statement .footer {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.statement .show-paper {
  display: none;
}

.statement .tenant-balance {
  width: 100%;
  margin-top: 10pt;
}

.statement .top-right-block {
  width: 100%;
}

/* styles for paper print */

.statement__isPaper * {
  color: #000;
}

.statement__isPaper .rows-with-line {
    margin-top: 3pt;
    width: 100%;
}
.statement__isPaper .section-title {
  background-color: initial;
  border: 1pt solid #000;
  color: #000;
  padding: 3pt 5pt;
  margin-top: 3pt;
}

.statement__isPaper a {
  color: #000;
}

.statement__isPaper .total {
  color: #000;
}

.statement__isPaper .primary-color {
  color: #000;
}

.statement__isPaper .half-section svg path {
  fill: #000;
}

.statement__isPaper .saver {
  background-color: initial;
  border: 1px solid #000;
  border-radius: 2px;
  margin-bottom: 10pt;
  width: 100%;
  padding: 2pt 4pt;
}

.statement__isPaper .cut-saver-section {
  /*border-top: none;*/
  display: none;
  /*width: initial;*/
}

.statement__isPaper .light-text {
  color: #000;
}

.statement__isPaper .graphics {
  display: none;
}

.statement__isPaper .show-paper {
  display: initial;
}

.statement__isPaper .show-e {
  display: none;
}

.statement__isPaper .section {
  /* lob guidelines */
  /* border: 1pt solid blue; */
  height: 264pt;
}

.statement__isPaper .lob-address {
  width: 228pt;
  height: 148pt;
  /* lob guidelines */
  /* background-color: red;
  opacity: 0.1; */
}

.statement__isPaper .top-right-block {
  margin-left: 42pt;
  flex-grow: 1;
}

.statement__isPaper .tenant-balance {
  margin-top: 15pt;
}

.statement__isPaper .balance {
  border-top: none;
  display: initial;
  width: initial;
}

.statement__isPaper .tenant-info {
  background-color: initial;
  width: initial;
  padding: 0;
  margin-right: initial;
}